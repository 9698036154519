import { useEffect } from 'react'
import {LAST_LOCATION} from "~/config/settings";
import {useRouter} from "next/router";
import {useAuth} from "@clerk/clerk-react";

export const getRedirectToLastUrl = () => {
  let lastUrl
  try {
    lastUrl = localStorage.getItem(LAST_LOCATION)
  }catch(err) {
    // nothing here
  }
  return lastUrl || "/shardlibrary"
}

export const useRedirectToLastUrl = () => {
  const router = useRouter()
  const {isLoaded, isSignedIn} = useAuth()

  useEffect(() => {
    if(isLoaded && isSignedIn) {
      router.replace(getRedirectToLastUrl())
    }
  }, [isLoaded, isSignedIn])
}
